var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"WorkerShowResumeDuplicated"},[_c('div',{staticClass:"mainContentContainer"},[_c('div',{staticClass:"cardsContainer"},_vm._l((_setup.props.workers),function(worker,index){return _c('card',{key:index,staticClass:"identityCard",attrs:{"disableScroll":""},on:{"click":function($event){return _setup.emits('showSummuryWorker', index)}}},[_c('div',{staticClass:"identity"},[_c(_setup.Avatar,{staticClass:"x-large round",attrs:{"image":""}}),_c('div',{staticClass:"infos"},[_c('h4',{class:{'diffentField': !_setup.isSameAsOriginal(`${_setup.props.refWorker.firstname.value} ${_setup.props.refWorker.lastname.value}`, `${worker.firstname} ${worker.lastname}`)}},[_vm._v(_vm._s(worker.firstname)+" "+_vm._s(worker.lastname))]),_c(_setup.Tag,{staticClass:"small"},[_vm._v(_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.databaseOrigin.' + worker.databaseOrigin)))])],1)],1),_c('div',{staticClass:"comesFrom"},[_c('div',{staticClass:"line"},[_c('p',[_vm._v(_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.comesFrom')))])]),_c('div',{staticClass:"tagsContainer"},[(worker.databaseOrigin === _setup.ESearchWorkerModes.EVOLIA)?_c(_setup.Tag,{staticClass:"medium neutral"},[_vm._v(" "+_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.evolia'))+" ")]):_vm._e(),_vm._l((worker.agencies),function(agency,index){return ((worker.databaseOrigin === _setup.ESearchWorkerModes.INTERIM) || (worker.databaseOrigin === _setup.ESearchWorkerModes.CANDIDATE))?_c(_setup.Tag,{key:index,staticClass:"neutral medium"},[_vm._v(" "+_vm._s(agency.name)+" "+_vm._s(agency.address.city)+" ")]):_vm._e()})],2)]),_c('div',{class:{
                            'diffentField': !_setup.isSameAsOriginal(_setup.props.refWorker.birthDate.value, _setup.moment(worker.birthDate).format(_setup.i18n.t('date.format'))),
                            'line': true
                        }},[_c(_setup.NewIcon,{attrs:{"icon":"design-system-calendar"}}),(_setup.canDisplayField(worker.birthDate))?_c('p',[_vm._v(_vm._s(_setup.moment(worker.birthDate).format(_setup.i18n.t('date.format'))))]):_c('p',[_vm._v(_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.notFill.birthDate')))])],1),_c('div',{staticClass:"line"},[_c(_setup.NewIcon,{attrs:{"icon":"design-system-location-marker"}}),(_setup.canDisplayField(worker.birthPlace))?_c('p',[_vm._v("Né à "+_vm._s(worker.birthPlace))]):_c('p',[_vm._v(_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.notFill.birthPlace')))])],1),_c('div',{staticClass:"line"},[_c(_setup.NewIcon,{attrs:{"icon":"design-system-credit-card"}}),_c('p',[_vm._v("Numéro de sécurité sociale")])],1),(_setup.canDisplayField(worker.birthPlace))?_c(_setup.TextInput,{class:{
                        nirCard: true,
                        semibold: true,
                        diffentField: !_setup.isSameAsOriginal(_setup.props.refWorker.nir.value, worker.nir),
                    },attrs:{"readonly":"","value":worker.nir,"mode":"nir"}}):_c(_setup.TextInput,{class:{
                        nirCard: true,
                        semibold: true,
                        diffentField: !_setup.isSameAsOriginal(_setup.props.refWorker.nir.value, worker.nir)
                    },attrs:{"readonly":"","value":_setup.i18n.t('modals.WorkerAddVerificationModal.notFill.nothing')}})],1)}),1)]),_c('p',{staticClass:"captions"},[_vm._v("* Champ(s) différent(s)")]),_c('div',{staticClass:"modal-actions"},[_c('div',{staticClass:"footerContainer"},[_c('p',[_vm._v(_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.createNewWorkerIfNoDuplicated')))]),_c(_setup.ActionButton,{staticClass:"outline medium",on:{"onClick":function($event){return _setup.emits('createNewWorkerSheet')}}},[_vm._v(_vm._s(_setup.i18n.t('modals.WorkerAddVerificationModal.createSheet')))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }