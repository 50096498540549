
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "SelfInitLayout"
})
export default class SelfInitLayout extends Vue {
    @Prop(Boolean) selfInit!: boolean;

    filters!: any;

    onFilters() {
        this.$emit('input', this.filters);
    }

    beforeMount() {
        if (this.selfInit) {
            this.$watch("filters", this.onFilters, {deep: true})
        }
    }
}
